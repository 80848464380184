import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { patchData } from "../feathers";
import { useCurrentUser, useCurrentUserIsSuperAdmin } from "../Persistence/CurrentUserContext"
import { useTerminalList } from "../Persistence/TerminalContext"


export const TerminalsMonitor = () => {
    const terminalList = useTerminalList()
    const isSuperAdmin = useCurrentUserIsSuperAdmin();
    const currentUser = useCurrentUser();

    const sendTerminalAlife = async (terminal) => {
        await patchData(ENUM_SERVICES.LASTALIFE, { time: new Date(), terminalKey: terminal.serialnumber, updatedBy: currentUser.id }, { user: currentUser })
    }

    const renderTerminal = (terminal) => {
        return <div key={'terminal-' + terminal.id}
            className={'flex flex-column border-bottom-1 m-1 p-1 surface-border opacity-background ' + (terminal.overdueTime ? 'terminal-monitor-status-fail' : 'terminal-monitor-status-ok')}
            onClick={() => sendTerminalAlife(terminal)}>
            <div className='flex flex-grow-1'>
                {`${terminal.name} (${terminal.location})`}
            </div>
            <div className={terminal.overdueTime ? 'flex w-full ' : 'hidden'}>
                {`${terminal.overdueTime} inaktiv`}
            </div>
        </div >
    }

    return <div className='hidden lg:flex flex-column  m-3 w-15rem' >
        <div className='flex text-color px-3 p-2 w-full bg-white opacity-90 gap-2'>
            Status Terminals
        </div>
        <div className='flex flex-column headline p-2'>
            {terminalList.filter((e) => e.active === 1).map((e) => renderTerminal(e))}
        </div>
    </div>
}