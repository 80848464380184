import { DataTable } from "primereact/datatable";
import Headline from "../Components/Headline";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import client, { getData, patchData } from "../feathers";
import { useCurrentUser, useCurrentUserIsSuperAdmin } from "../Persistence/CurrentUserContext"
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { useEffect, useRef, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { useDepartmentList } from "../Persistence/DepartmentContext";
import { useNavigate } from "react-router-dom";
import { addDays, format, startOfDay } from "date-fns";
import { Dropdown } from "primereact/dropdown";
import { DateRangeFilterElement } from "../Components/DateRangeFilterElement";
import { openBase64PdfInNewTab } from "../snippets";
import { Toast } from "primereact/toast";
import { ENUM_ICONS } from "../Components/Icons";


export const OperationLists = () => {
    const dpartmentList = useDepartmentList();
    const [paginationFirst, setPaginationFirst] = useState(0);
    const [paginationRows, setPaginationRows] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
    const [dataTableList, setDataTableList] = useState([]);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [filters, setFilters] = useState({
        'alarmDate': { value: null, matchMode: FilterMatchMode.BETWEEN, where: `strftime('%s', alarmDate) * 1000  BETWEEN #value0# AND #value1#` },
        'departmentId': { value: null, matchMode: FilterMatchMode.IN, where: 'departmentId IN (#valueList#)' },
        'subject': { value: null, matchMode: FilterMatchMode.CONTAINS, where: `subject LIKE '%#value#%'` },
        'createdAt': { value: null, matchMode: FilterMatchMode.BETWEEN, where: `strftime('%s', regdata_list.createdAt) * 1000  BETWEEN #value0# AND #value1#` },
        'listtype': { value: null, matchMode: FilterMatchMode.EQUALS, where: 'listtype = #value#' }
    });
    const [mounted, setMounted] = useState(false);
    const [totalRecords, setTotalRecords] = useState(null);
    const [orderBy, setOrderBy] = useState('');
    const [where, setWhere] = useState('');
    const [clearAlarmDateFilter, setClearAlarmDateFilter] = useState();
    const toast = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (mounted) {
            getRegdataList()
        }
    }, [mounted, filters, currentPage, paginationRows, orderBy, where])

    useEffect(() => {
        setMounted(true)
        setWhere(computeWhere(filters))
    }, [])

    const getRegdataList = async () => {
        client.service(ENUM_SERVICES.REGDATALIST).find({ query: { orderBy, where, paginationRows, paginationFirst } }).then((result) => {
            setTotalRecords(result.total);
            setDataTableList(result.data)
        })

    }

    const onDoubleClick = async (e) => {
        await getData(ENUM_SERVICES.REGDATALIST, e.data.id).then(async (result) => {
            console.log('regdata-list:', result)
            if (result.pdfBase64) {
                await openBase64PdfInNewTab(result.path, result.pdfBase64)
            } else {
                toast.current.show({
                    severity: 'error', summary: 'Liste nicht gefunden', content: (
                        <div className="flex w-full">
                            <div className="flex flex-column py-auto px-2">
                                <i className="pi pi-exclamation-triangle " style={{ fontSize: '3rem' }}></i>
                            </div>
                            <div className="flex flex-column ">
                                <div className='text-lg'>Das Pdf für die Liste vom</div>
                                <div className='text-lg font-bold'>{format(new Date(result.alarmDate), 'dd.MM.yyyy HH:mm:ss')}</div>
                                <div className='text-lg'>wurde nicht gefunden</div>
                            </div>
                        </div>
                    ), life: 7000
                });
            }


        })
    }

    const dateRangeFilterAlarmDate = (options) => {
        options.value = [addDays(startOfDay(Date.now()), -2).getTime(), undefined]
        return <DateRangeFilterElement options={options} onFilterClearParent={(callback) => setClearAlarmDateFilter(() => callback)} />
    }

    const listtypeFilter = (options) => {
        return <Dropdown
            className={' dropdown-custom '}
            id={'dropdown_listtypefilter'}
            value={options.value}
            optionValue={'value'}
            optionLabel={"name"}
            options={[{ name: 'Alarm', value: 1 }, { name: 'Übung', value: 2 }]}
            onChange={(e) => {
                options.filterApplyCallback(e.value)
            }}
            placeholder={'Filter Einsatztyp'} />
    }

    const departmentFilter = (options) => {
        return <MultiSelect
            className={' multiselect-custom '}
            id={'multiSelectFilter_department'}
            value={options.value || ''}
            optionValue={'id'}
            optionLabel={"name"}
            options={dpartmentList}
            onChange={(e) => {
                console.log('departmentFilter:', e)
                options.filterApplyCallback(e.value)
            }}
            placeholder={'Filter Abteilungen'}
            selectedItemsLabel={(options.value ? options.value.length : 0) + ' Abteilungen ausgewählt'}
            maxSelectedLabels={2} />
    }

    const onPageChange = (e) => {
        setPaginationFirst(e.first);
        setPaginationRows(e.rows);
        setCurrentPage(e.page)
        console.log('onPageChange:', e)
    }

    const onSort = (e) => {
        setSortField(e.sortField)
        setSortOrder(e.sortOrder)
        setOrderBy(e.sortField ? `ORDER BY ${e.sortField} ${e.sortOrder === 1 ? 'ASC' : 'DESC'}` : '')
        console.log('onSort:', e)
    }

    const computeWhere = (filters) => {
        let where = ''
        for (let field of Object.getOwnPropertyNames(filters)) {
            if (filters[field].value) {
                if (Array.isArray(filters[field].value) && filters[field].value.length > 0) {
                    let counter = 0
                    let fieldWhere = filters[field].where
                    fieldWhere = fieldWhere.replaceAll(`#valueList#`, filters[field].value.join(','))
                    for (let value of filters[field].value) {
                        fieldWhere = fieldWhere.replaceAll(`#value${counter++}#`, value || 999999999999999)
                    }
                    where += (where !== '' ? ' AND ' : '') + fieldWhere
                } else if (!Array.isArray(filters[field].value)) {
                    where += (where !== '' ? ' AND ' : '') + (filters[field].where || '').replaceAll('#value#', filters[field].value)
                }
            }
        }
        return where !== '' ? `WHERE ${where}` : ''
    }

    const onFilter = (e) => {
        setFilters(e.filters)
        setWhere(computeWhere(e.filters))
    }

    const paginatorTemplate = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 25, value: 25 },
                { label: 50, value: 50 },
                { label: 100, value: 100 }
            ];

            return (
                <>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Einträge pro Seite: </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </>
            );
        },
        'CurrentPageReport': (options) => {
            return (<div style={{ marginLeft: '20px', color: 'var(--text-color)', userSelect: 'none', width: '200px', textAlign: 'center' }}>
                <span >
                    {options.first} - {options.last} von {totalRecords}
                </span>
                <span style={{ marginLeft: '20px', marginRight: '20px', color: 'var(--text-color)', userSelect: 'none', width: '30px', textAlign: 'center' }}>
                    Seite {currentPage + 1}
                </span>
            </div>
            )
        }
    };

    const buttons = [
        { key: 'OperationListsButton_1', label: 'Hauptwache', command: () => navigate('/') },
    ]

    const renderIdColumn = (rows) => {
        return <div className='flex gap-2 edit-icon'><i className={'pt-1 ' + ENUM_ICONS.PENCIL} onClick={() => onDoubleClick({ data: rows })} /><div>{format(rows.alarmDate, 'dd.MM.yyyy')}</div></div>
    }

    return (!mounted ? <></> :
        <div className='flex flex-column w-full'>
            <Toast ref={toast} />
            <Headline buttons={buttons} />
            <div className='mx-auto eaa-dataView' style={{ maxWidth: 'calc(100vw - 200px)' }}>
                <DataTable
                    value={dataTableList}
                    emptyMessage={'keine Einträge gefunden'}
                    scrollHeight={'calc(100vh - 200px)'}
                    selectionMode="single"
                    scrollable
                    removableSort
                    filterDisplay='row'
                    filters={filters}
                    paginator
                    paginatorTemplate={paginatorTemplate}
                    onSort={onSort}
                    onPage={onPageChange}
                    onFilter={onFilter}
                    first={paginationFirst}
                    rows={paginationRows}
                    totalRecords={totalRecords}
                    lazy
                    sortField={sortField}
                    sortOrder={sortOrder}
                    responsiveLayout="scroll"
                    dataKey="id"
                    stripedRows
                    rowHover
                    footer={() => <div>Anzahl der angezeigten Einsatzlisten: {totalRecords}</div>}
                    onRowDoubleClick={onDoubleClick}
                >
                    <Column
                        header='Datum'
                        field='alarmDate'
                        body={renderIdColumn}
                        filter
                        filterElement={dateRangeFilterAlarmDate}
                        onFilterClear={clearAlarmDateFilter}
                        style={{ width: '150px' }}
                        sortable
                        showFilterMenu={false}
                    />
                    <Column
                        header='Zeitraum'
                        field='createdAt'
                        body={(rows) => `${format(rows.alarmDate, 'HH:mm:ss')} - ${format(rows.createdAt, 'HH:mm:ss')}`}
                        style={{ width: '200px' }}
                        sortable
                        showFilterMenu={false} />
                    <Column
                        field='depatmentId'
                        header='Abteilung'
                        body={(row) => row.departmentName}
                        style={{ width: '300px' }}
                        filter={true}
                        filterField="departmentId"
                        filterElement={departmentFilter}
                        showFilterMenu={false}
                    />
                    <Column
                        header='Einsatzart'
                        field='listtype'
                        body={(row) => row.listtype === "1" ? 'Alarm' : 'Übung'}
                        filter
                        filterElement={listtypeFilter}
                        style={{ width: '200px' }}
                        showFilterMenu={false}
                    />
                    <Column
                        header='Thema'
                        field='subject'
                        filter
                        style={{ flexGrow: 1, }}
                        sortable
                        showFilterMenu={false} />
                </DataTable>
            </div>
        </div>

    )
}