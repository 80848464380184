import { endOfDay, format, parse } from 'date-fns';
import { Calendar } from 'primereact/calendar';
import { InputMask } from 'primereact/inputmask';
import { useRef, useEffect, useState } from 'react';
import { logDefault } from '../logger';


const CLASSNAME = 'DateRangeFilterElement';
export const DateRangeFilterElement = ({ options, minDate, maxDate, onFilterClearParent }) => {
    const calendarRef = useRef();
    const [filter, setFilter] = useState();

    const [start, setStart] = useState();
    const [startPicker, setStartPicker] = useState();
    const [end, setEnd] = useState();
    const [endPicker, setEndPicker] = useState();
    const [validStartDate, setValidStartDate] = useState(true);
    const [validEndDate, setValidEndDate] = useState(true);
    const [mounted, setMounted] = useState()

    useEffect(() => {
        if (mounted && filter) {
            const currentFilter = filter.value || [undefined, undefined]
            if (start === '' && end === '') {
                options.filterApplyCallback(null);
            } else {
                const dateStart = parse(start, 'dd.MM.yyyy', new Date())
                const dateEnd = parse(end, 'dd.MM.yyyy', new Date())

                const startFilter = (('' + dateStart) !== 'Invalid Date') ? dateStart.getTime() : 0;
                const endFilter = (('' + dateEnd) !== 'Invalid Date') ? endOfDay(dateEnd).getTime() : 99999999999999;

                setValidStartDate(('' + dateStart) !== 'Invalid Date')
                setValidEndDate(('' + dateEnd) !== 'Invalid Date')

                setStartPicker(startFilter !== 0 ? new Date(startFilter) : undefined)
                setEndPicker(endFilter !== 99999999999999 ? new Date(endFilter) : undefined)

                if (currentFilter[0] !== startFilter || currentFilter[1] !== endFilter) {
                    if (startFilter !== 0 || endFilter !== 99999999999999) {
                        options.filterApplyCallback([startFilter, endFilter]);
                    }

                }
            }

        }
    }, [start, end, mounted])

    useEffect(() => {
        onFilterClearParent(onFilterClear)
        setFilter(options.value || [undefined, undefined])
        const start = options.value && options.value[0] && options.value[0] > 0 ? format(new Date(options.value[0]), 'dd.MM.yyyy') : ''
        const end = options.value && options.value[1] && options.value[1] < 99999999999999 ? format(new Date(options.value[1]), 'dd.MM.yyyy') : ''
        setStart(start)
        setEnd(end)
        setMounted(true)
    }, [])

    const onFilterClear = () => {
        setStart(undefined)
        setEnd(undefined)
    }



    return (
        <div className={' flex filterCalendar '}>
            <div className={'flex flex-column gap-1 flex-grow-0 ' + (filter && filter.value && filter.value.length > 0 ? 'eaa-filter-active' : '')}>
                <div className='flex'>
                    <InputMask
                        mask="99.99.9999"
                        slotChar="TT.MM.JJJJ"
                        className={(validStartDate ? '' : 'text-red-500') + ' inputmask '}
                        value={start}
                        onChange={(e) => setStart(e.value)}
                    />

                    <Calendar id='filterCalendarStart'
                        tabIndex={-1}
                        style={{ height: '25px', width: '25px' }}
                        value={startPicker}
                        showIcon
                        inputClassName='p-0'
                        inputStyle={{ height: '0px' }}
                        ref={calendarRef}
                        dateFormat="dd.mm.yy"
                        maxDate={maxDate ? new Date(maxDate) : undefined}
                        onChange={(e) => {
                            setStart(format(e.value, 'dd.MM.yyyy'))
                        }}
                    />
                </div>

                <div className='flex'>
                    <InputMask
                        mask="99.99.9999"
                        slotChar="TT.MM.JJJJ"
                        className={(validEndDate ? '' : 'text-red-500') + ' inputmask '}
                        value={end}
                        onChange={(e) => setEnd(e.value)}
                    />

                    <Calendar id='filterCalendarEnd'
                        tabIndex={-1}
                        style={{ height: '25px', width: '25px' }}
                        value={endPicker}
                        showIcon
                        inputClassName='p-0'
                        inputStyle={{ height: '0px' }}
                        ref={calendarRef}
                        dateFormat="dd.mm.yy"
                        minDate={minDate ? new Date(minDate) : undefined}
                        onChange={(e) => {
                            setEnd(format(e.value, 'dd.MM.yyyy'))
                        }}
                    />
                </div>

            </div>
            <div className='flex flex-grow-1' />
        </div>
    )
}