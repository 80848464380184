import { useCallback, useEffect, useState } from "react";
import { Department } from "./Department"
import { Carousel } from 'primereact/carousel';

export const Departments = ({ departments, subject, showTerminaldata }) => {

    const departmentList = departments.map(e => {
        const width = `calc(100vw /${departments.length > 0 ? departments.length : 1})`
        return <Department
            key={'department_' + e.id}
            subject={subject}
            department={e}
            style={{ height: `calc((100vh - 100px)/${departments.length > 6 ? 2 : 1})`, width, maxWidth: '300px' }}
        />
    })

    const responsiveOptions = [
        {
            breakpoint: '800px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const itemTemplate = useCallback((department) => {
        return <div className='flex w-full justify-content-center'>
            <Department
                key={'department_' + department.id}
                subject={subject}
                department={department}
                showTerminaldata={showTerminaldata}
                classHeight={departments.length > 6 ? 'h-30rem' : 'h-full'}
                style={{ height: 'calc(100vh - 100px)', width: '350px' }} />
        </div>
    }, [subject])

    return <div
        className='flex flex-wrap flex-grow-1 my-3'
        style={{ maxWidth: 'calc(100vw)', height: 'calc(100vh - 100px)' }}>
        <div className='hidden xl:flex w-full justify-content-center gap-3 '>
            {departmentList}
        </div>
        <div className='flex xl:hidden w-full justify-content-center '>
            <Carousel
                className="flex w-full"
                contentClassName="flex w-full "
                value={departments}
                numVisible={1}
                numScroll={1}
                responsiveOptions={responsiveOptions}
                itemTemplate={itemTemplate}
            />
        </div>

    </div>
}