import { format, differenceInSeconds, differenceInMilliseconds } from "date-fns";

const dateFormat = 'yyy-MM-dd';
//export const formatDate = (date) => { return format(date || new Date(), dateFormat) }
export const formatDateTime = (date) => { return format(date || new Date(), dateFormat + ' HH:mm') }

export const copyObject = (object) => {
	return JSON.parse(JSON.stringify(object))
}

export const printStopwatchTime = (title, start, millis) => {
	const difference = (millis ? differenceInMilliseconds(new Date(), start) + ' milliseconds' : differenceInSeconds(new Date(), start) + ' seconds')
	console.info(`stopwatch: '${title}' takes ${difference}`)
}

export const openBase64PdfInNewTab = async (title, base64Pdf) => {
	// Convert Base64 string to a Blob
	const byteCharacters = atob(base64Pdf);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const blob = new Blob([byteArray], { type: 'application/pdf' });

	// Create a URL for the Blob
	const blobUrl = URL.createObjectURL(blob);

	// Open a new tab with the Blob URL
	const newTab = window.open(blobUrl, '_blank');

	// Set the title of the new tab (when possible)
	if (newTab) {
		newTab.onload = function () {
			newTab.document.title = title; // Set the window/tab title
		};
	}
}

export const getUniqueList = (list, fieldname) => {
	return list.filter((item, index, self) =>
		index === self.findIndex((t) => t[fieldname] === item[fieldname])
	)
}

export const getSortedList = (list, fieldname, descending) => {

	const listSorted = list.sort((a, b) => {
		let x = a[fieldname];
		let y = b[fieldname];
		if (typeof x === 'string' || x instanceof String) { x = x.toLowerCase() }
		if (typeof y === 'string' || y instanceof String) { y = y.toLowerCase() }
		if (x < y) { return !descending ? -1 : 1 }
		if (x > y) { return !descending ? 1 : -1 }
		return 0;
	})
	return listSorted
}