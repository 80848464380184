import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { useEffect, useState } from "react";
import { usePersonList } from "../Persistence/PersonContext";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { Dialog } from "primereact/dialog";
import { patchData } from "../feathers";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useCurrentUser } from "../Persistence/CurrentUserContext";



export const UnknownRFIDs = ({ displayDialog, setDisplayDialog, departmentId, departmentName, rfid }) => {
    const currentUser = useCurrentUser();
    const personList = usePersonList();
    const [dataTableList, setDataTableList] = useState([]);
    const [filters, setFilters] = useState({
        'lastname': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'id': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'firstname': { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [mounted, setMounted] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        setDataTableList(personList.filter((e) => e.departmentId === departmentId))
    }, [departmentId])

    const onSelect = (e) => {
        console.log(e)
    }

    const onValueChanged = (data) => {
        setTotalRecords(data ? data.length : 0)
    }

    const assignRFID = async () => {
        await patchData(ENUM_SERVICES.RFIDTAGS, { rfid, personId: selected.id, updatedBy: currentUser.id }).then(() => {
            setDisplayDialog(false)
        })
    }

    const renderFooter = () => {
        return <div>Anzahl der angezeigten Personen: {totalRecords}</div>
    }

    return <div key={'UnknownRFIDs'} className={'flex flex-column department-card '} >
        <Dialog
            className="flex"
            style={{ height: '720px' }}
            header={<div className='text-xl '>FFW-GD - RFID {rfid} einer Person zuweisen</div>}
            visible={displayDialog}
            footer={
                <div>
                    <Button label="Abbrechen" icon="pi pi-times" onClick={() => setDisplayDialog(false)} autoFocus className='button-cancel' />
                    <Button label={`${selected ? selected.lastname + ', ' + selected.firstname : ''}   zuweisen`} icon="pi pi-check" onClick={() => assignRFID()} className="p-button-text" disabled={!selected} />
                </div>
            }
            onHide={() => setDisplayDialog(false)}>
            <div className="flex flex-column gap-1 text-lg" >
                <div className='flex text-xl my-1' >Abteilung: {departmentName}</div>
                <DataTable
                    value={dataTableList}
                    onValueChange={onValueChanged}
                    emptyMessage={'keine Einträge gefunden'}
                    scrollHeight={'500px'}
                    selectionMode="single"
                    selection={selected}
                    onSelectionChange={e => setSelected(e.value)}
                    scrollable
                    removableSort
                    sortField="lastname" sortOrder={1}
                    filterDisplay='row'
                    filters={filters}
                    responsiveLayout="scroll"
                    dataKey="id"
                    stripedRows
                    rowHover
                    onSelect={onSelect}
                    footer={renderFooter}
                >
                    <Column header='Id' field='id' style={{ flexGrow: 0, flexBasis: '80px' }} sortable showFilterMenu={false} />
                    <Column header='Name' field='lastname' filter style={{ flexGrow: 1, flexBasis: '200px' }} sortable showFilterMenu={false} />
                    <Column header='Vorname' field='firstname' filter style={{ flexGrow: 1, flexBasis: '200px' }} sortable showFilterMenu={false} />
                </DataTable>
            </div>
        </Dialog>
    </div>
}